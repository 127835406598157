<template>
  <div class="wallet">
    <div class="advContainer">
      <van-image class="advImg" :src="advimg" />
    </div>
    <van-grid class="walletBarBox" :column-num="2">
      <van-grid-item class="balanceOfAccountBox">
        <div class="balanceOfAccount" slot="text">
          <div class="Account">{{$t('THEACCOUNTBALANCE')}}({{$t('UNIT')}})</div>
          <div class="balance">{{balance}}</div>
        </div>
      </van-grid-item>
      <van-grid-item class="stayBalanceOfAccountBox">
        <div class="stayBalanceOfAccount" slot="text">
          <div class="Account">{{$t('AWAITJIE')}}</div>
          <div class="stayBalance">{{refund}}</div>
        </div>
      </van-grid-item>
    </van-grid>
    <div class="btnContainer">
      <!-- <van-button @click="btns(btn.recharge)" :class="{ active: btn.recharge.active }" class="recharge" type="default" block>{{$t('LIJIRECHARGE')}}</van-button> -->
      <!-- <van-button @click="btns(btn.withdrawDeposit)" :class="{ active: btn.withdrawDeposit.active }" class="withdrawDeposit" type="default" block>{{$t('LIJITIXIAN')}}</van-button> -->
    </div>
    <router-view/>
  </div>
</template>

<script>
import { getAmountMoney, getadv } from '@/api/wallet'
export default {
  name: 'wallet',
  data () {
    return {
      btn: {
        recharge: {
          value: 1,
          active: true,
          name: 'rechargep'
        },
        withdrawDeposit: {
          value: 2,
          active: false,
          name: 'withdrawdeposit'
        }
      },
      balance: 0.00,
      refund: 0.00,
      // 钱包广告
      advimg: ''
    }
  },
  computed: {
  },
  methods: {
    btns (obj) {
      for (const key in this.btn) {
        this.btn[key].active = false
      }
      obj.active = true
      this.$router.push({
        name: obj.name
      })
    },
    async loadMoney () {
      const { data } = await getAmountMoney()
      if (data.code === 200) {
        this.balance = Number(data.data.amountMoney).toFixed(2)
        this.refund = Number(data.data.withdrawalAmount).toFixed(2)
      } else {
        this.balance = Number(this.balance).toFixed(2)
        this.refund = Number(this.refund).toFixed(2)
      }
    },
    async loadv () {
      const { data } = await getadv({
        type: '4'
      })
      if (data.code === 200) {
        this.advimg = data.data[0].imageUrl
      }
    }
  },
  created () {
    this.$router.push({
      path: '/wallet/loansComponent'
    })
    this.loadMoney()
    this.loadv()
  }
}
</script>

<style scoped>
.advContainer {
  width: 100%;
  height: 150px;
}
.advImg {
  width: 100%;
  height: 100%;
}
.Account{
  font-size: 16px;
}
.balance,
.stayBalance {
  font-size: 20px;
  color: #f60;
  padding: 5px 0;
}
.btnContainer {
  overflow: hidden;
  box-sizing: border-box;
  padding: 20px;
  border-bottom: 1px solid #e1e1e1;
}
.recharge,
.withdrawDeposit {
  width: 150px;
  height: 50;
  float: left;
  font-size: 16px;
}
.withdrawDeposit {
  margin-left: 30px;
}
.active {
  background: #f60;
  color: #fff;
}
</style>
